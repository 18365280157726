// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile =  {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:9d83ead4-65db-4cc3-9579-cde99bc86007",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_VHgRmiukU",
    "aws_user_pools_web_client_id": "75jlh67esgfc27m7ln8fujlcdi",
    "aws_mobile_analytics_app_id": "49061f65a4264a74b63c098597c8ac2a",
    "aws_mobile_analytics_app_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://yqdgqwtqc5bdnef75l36fufcim.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
