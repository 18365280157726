import React from 'react';
import  moment from  'moment'
import  PopulateData from '../populate';
export class DisplayAgenda extends React.Component{

 

    constructor(props){
        super(props);

        this.state = {
            
            startAt : '',
            endAt : '',
            title : '',
            location : '',
            eveDate : '',
            today :  '',
            isAllDay : '',
            // totalEveDays : this.props.createdEvent.endAt.diff
            }

            this.assignValue = this.assignValue.bind(this);
    }
    
      assignValue(){

        this.state.startAt = moment(this.props.createdEvent.startAt).format('hh:mm A');
        this.state.endAt = moment(this.props.createdEvent.endAt).format('hh:mm A');
        this.state.title = this.props.createdEvent.title;
        this.state.location= this.props.createdEvent.location;
        this.state.eveDate = moment(this.props.createdEvent.eventDate).format('ddd MMM D');
        this.state.today = moment().format('ddd MMM D');
        this.state.isAllDay = this.props.createdEvent.isAllDay;
        
    }
      
    render(){
     this.assignValue();
      
        return(
           
            // Mon Feb 5
            <div className="">
            <PopulateData createdEvent={this.state} startStamp={this.props.createdEvent.eventDate} />
           
   
            </div>
        );
        }
    }



