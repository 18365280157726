import React, { Component } from 'react';
import '../App.css';
import Month from "./Month";
import MonthDetail from "./MonthDetail";
import  moment from  'moment/moment';
import back from './back.png'
import ReactDOM from 'react-dom'

class YearDetail extends Component {
    weekdayshort = moment.weekdaysShort();
    constructor(props) {
        console.log('vvjjgj YearDetail constructor');

        super(props)

        this.getArrayMonths = this.getArrayMonths.bind(this);
        // var monthName = this.props.location.state.monthName;
        var year = this.props.year;
        var monthName = this.props.monthName;
        var month = moment().month(monthName).format("M");
        month = parseInt(month)-1 ;
        if (month <9){
            month = month+1;
        }
        var id = [month,year];
        id = id.join('-');
        console.log('go to state');
        this.state = {
            year : year,
            'currentYear' : moment().year(),
            'months' : [0,1,2,3,4,5,6,7,8,9,10,11],
            // 'months' : [],
            'className' : 'display-inline-flex',
            'next': false,
            'id' : id,
            month: month,
            'array' :[],
            'scrollUp' : false,
            'scrollDown' : false,
            'nextYear' : year+1,
            'yearToDisplay': year,
            arrayWajid: []
        }


    }
    componentWillMount(){

        let arrayMonths = [];
        for(let i = 0; i < this.state.months.length; i++) {
            arrayMonths.push(
                <MonthDetail key={i}  month = {this.state.months[i]} year ={this.state.year} className = {this.state.className}
                showWeek = {this.props.showWeek}  yearOnDisplay={this.yearOnDisplay}
                createdEvents = {this.props.createdEvents} />
            );
        }

        this.setState({
            arrayWajid: arrayMonths
        });
    }

    componentDidMount() {
        this.scroll();
        window.addEventListener('scroll', this.onScroll, false);

    }

    
    componentDidUpdate(prevProps) {
        if(prevProps.year !== this.props.year) {
            console.log('go to update');
          this.setState({year: this.props.year});
          this.forceUpdate();
        }
      }


    componentWillUnmount() {
        window.removeEventListener('scroll', this.onScroll, false);
    }
    componentWillReceiveProps(nextProps) {
        console.log('vvjjgj YearDetail component Will Receive Props',nextProps);

        // 'months' : [0,1,2,3,4,5,6,7,8,9,10,11],
        this.setState({ 
            arrayWajid: [] ,
            year: nextProps.year,
            month: nextProps.month
        });

        setTimeout(function() {
            this.setState({
                arrayWajid: this.getArrayMonths()
            });
        }.bind(this), 0.00000001);

        var year = nextProps.year;
        var monthName = nextProps.monthName;
        var month = moment().month(monthName).format("M");
        month = parseInt(month)-1 ;
        if (month <9){
            month = month+1;
        }
        var id = [month,year];
        id = id.join('-');
        this.setState({ year: year });
        this.setState({ month: month });
        this.setState({ id: id });  
        this.setState({ nextYear: year+1 });
        this.setState({ yearToDisplay: year });
        this.forceUpdate();

      }
    

    currentMonth () {
        console.log('current month clicked');
        // this.props = null;
        var yearV = 2010;
        this.setState({
            year: yearV
        });
        this.forceUpdate();
        console.log('go to year now',this.state.year);
        console.log('go to month',this.state.month);
    
    }

    onScroll = () => {

        console.log('id');
        let idElement = this.state.id;
        console.log(idElement);
        const element = document.getElementById(idElement);
        console.log('next', this.state.next);
        var inViewport = require('in-viewport');

        var isInViewport = inViewport(element);
        console.log(isInViewport);

        if (isInViewport){
            this.setState({next: true});
            console.log('in view true');
            console.log('go to next',this.state.next);
            var high = document.getElementById("high");
            this.addNextYear();
        }
        console.log(this.state.next);

    }

    getArrayMonths(){
        let arrayMonths = [];
        for(let i = 0; i < this.state.months.length; i++) {
            arrayMonths.push(
                <MonthDetail key={i} month = {this.state.months[i]} year ={this.state.year} className = {this.state.className}
                showWeek = {this.props.showWeek}  yearOnDisplay={this.yearOnDisplay}
                createdEvents = {this.props.createdEvents} />
            );
        }

        return arrayMonths;
    }

    addNextYear(){
        let updatedMonthsArray = this.state.arrayWajid;
        if (this.state.next){
            for (let d = 0 ; d < this.state.months.length; d++ ) {
                console.log('herenjns');
                updatedMonthsArray.push(
                    <MonthDetail month={d} year={parseInt(this.state.year)+1} className={this.state.className}
                                 showWeek={this.props.showWeek} yearOnDisplay={this.yearOnDisplay}/>
                )
            }
            var id = [this.state.month, parseInt(this.state.year)+1];
            this.setState({
                id: id.join('-'),
                year: parseInt(this.state.year) +1,
                arrayWajid: updatedMonthsArray,
                next: false
            })
        }
    }

    scroll() {
        const  id = this.props.monthName;

        if (!id) {
            return
        }
        const element = document.getElementById(id);

        console.log(element);
        console.log(id);
        if (element) {
            element.scrollIntoView({block: "center", inline: "nearest"});

        }
    }
    yearOnDisplay = (props) => {
        console.log('display',props);
        this.setState({yearToDisplay:props})
    }
    detect = (event) => {
        console.log('detect');
        if (event.nativeEvent.wheelDelta > 0) {
            console.log('scroll up');
            // this.setState({scrollUp: true});
            // this.setState({scrollDown: false});

        } else {
            console.log('scroll down');
            // this.setState({scrollUp: false});
            // this.setState({scrollDown: true});
        }
    }

    render() {

        let weekdayshortname = this.weekdayshort.map(day => {
            return <th className="" key={day}>{day}</th>;
        });


        console.log('vvjjgj YearDetail render this.state.arr ay', this.state.array);
        console.log('vvjjgj YearDetail render this.state.mon ths', this.state.months);



       // this.setState({next : false});
        //this.setState({year : this.state.year+1});
        console.log('vvjjgj render year',this.state.year);
        console.log('vvjjgj render month',this.state.month);

        return (
            //
             
            <div className="" id="high" onWheel = {(e) => this.detect(e)} >
                <div  className="col-sm-12 col-lg-12 weekend-sticky" >
                    <div className="row">
                        <div className="col-sm-9 col-lg-9">
                      <span  style={{color : 'red' ,display: 'flex',alignItems: 'flex-end'}} onClick={() => this.props.goToYearList( this.props)}>
                        <div><img src={back} alt="" style= {{height:'21px'}} /></div>
                             <div className="pointer" style={{fontSize:'medium',color : 'red' , marginBottom : '0px'}}>{` ${this.state.yearToDisplay}`}</div>
                         </span>
                         </div>
                         <div className="col-sm-3 col-lg-3">
                                <span style={{color:'red', cursor: 'pointer'}} onClick={() => this.props.goToCurrentMonth(this)}> Current Month</span>
                         </div>
                         </div>
                    {/* <p onClick={() => this.props.goToYearList( this.props)} style={{ color : 'red' , marginBottom : '0px'}}><span className="pointer">&lt;{this.state.yearToDisplay}</span></p> */}
                        <table className=" full-width">
                            <thead>
                            <tr style={{}}>{weekdayshortname}</tr>
                            {/*<tr className="month">{this.state.monthName}</tr>*/}
                            {/*<tr className={(this.state.month === this.state.currentMonth && this.state.year === this.state.currentYear) ? 'month-current' : 'month'}>{this.state.monthName}</tr>*/}
                            </thead>
                            {/*<Link  style={{ textDecoration: 'none' , color: 'black' }} to={{pathname: "/Day", state: { date : '2019-01-11' }}}>*/}
                            {/*<tbody>{daysinmonth}</tbody>*/}
                            {/*</Link>*/}

                        </table>


                </div>
                {/*<div style= {{paddingTop: '60px'}}></div>*/}
                {/*<p className = {this.state.year != this.state.currentYear ? 'year':'year-current'} id={this.state.year}>{this.state.year}</p>*/}

                <div className="row" >
                    {this.state.arrayWajid}

{/* 
                {this.state.months.map((item, key) =>
                    
                    <MonthDetail  month = {item} year ={this.state.year} className = {this.state.className}
                     showWeek = {this.props.showWeek}  yearOnDisplay={this.yearOnDisplay}
                     createdEvents = {this.props.createdEvents} />
                )} */}
           
         </div>
              
                {/*{ this.state.next ?*/}
                {/*this.state.months.map((item, key) =>*/}
                 {/*<MonthDetail  month = {item} year ={this.state.year + 1} className = {this.state.className} showWeek = {this.props.showWeek} />*/}
                {/*)*/}
                {/*: null}*/}
                <div id = 'yearTest' className="row" >
                    {/* {yearTest} */}
                 </div>
            </div>
         
        
        );
    }

}


export default YearDetail;