import React from 'react';
import  moment from  'moment'

function PopulateData (props)
{

    console.log('function',props.createdEvent)
    const {startAt,endAt,title,isAllDay,location} = props.createdEvent;
    const {startStamp} = props;
    const today = moment().format('ddd MMM D');
    const eveDate = moment(startStamp).format('ddd MMM D');
    console.log('function',eveDate)

    return(

    <div>
        <div className="col-12 agenda-date">
        <span className= {eveDate=== today ? 'my-curDay':''}>{eveDate}</span>
        </div>
        <div className="col-12">
        <div className="row agenda">
            {!isAllDay && (
        <div className="col-4 agenda-timings">

             <p className="agenda-time">{startAt}</p>
             <p className="agenda-time">{endAt}</p>
             </div>

        )}
        {!isAllDay || (
        <div className="col-4 agenda-timings">

        <p className="agenda-time">all-day</p>
        </div>

        )  
        }
               
            <div className="col-8 title-location">
                <p className="title">{title}</p>
                <p className="location">{location}</p>
            </div>
        </div>
    </div>
    </div>
    )

}
export default PopulateData;