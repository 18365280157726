import React, { Component } from 'react'
import Drawer from '@material-ui/core/Drawer'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Hidden from '@material-ui/core/Hidden'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListSubheader from '@material-ui/core/ListSubheader'
import Switch from '@material-ui/core/Switch'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { withStyles } from '@material-ui/core/styles'

const {CAFE} = window


const drawerWidth = 280;
const styles = theme => ({
  toolbar: theme.mixins.toolbar,
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: "90%",
    [theme.breakpoints.up('sm')]: {
    width: drawerWidth,
    }
  },
  grow: { 
    flexGrow: 1
  },
  calList: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  }
})


/* ************ MainNav *************  */
class MainNav extends Component {
  
  constructor(props) {
    super(props)
    this.state = {
    }
  }
  
  componentDidMount(){
  }
  
  handleSelectCal =  (value, event) => {
    //event.stopPropagation()
    const { selectedCals } = this.state
    const currentIndex = selectedCals ? selectedCals.indexOf(value) : -1
    const newChecked = selectedCals ? [...selectedCals] : []
    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }
    
    this.setState({
      selectedCals: newChecked
    })
  }
  
  
  
  render() {
    const me = this;
    const { classes, account, recentCals, selectedCals, currentCalId, savedCals} = me.props
    
    const authUser = account ? account.username : false
    
    const drawer = (
      <div>
        <Hidden xsDown implementation="css"><div className={classes.toolbar} /></Hidden>
        <Divider />
        <List>
            <ListItem>
              <Button  color="secondary" className={classes.button} onClick={()=>{this.props.ensureDrawerClose();CAFE.navigate("CREATE_CAL")}}>Create a new calendar</Button>
            </ListItem>
        </List>
        <List subheader={<ListSubheader>Recent Calendars</ListSubheader>} className={classes.calList}>
        {recentCals.map(cal => (
          <ListItem  button onClick={()=>{this.props.ensureDrawerClose();CAFE.navigate("CAL", {calId: cal.id, key: cal.accessKeys})}} key={cal.id} selected={cal.id === currentCalId}>
            <ListItemText color="secondary" primary={cal.title} />
            
              {cal.id !== currentCalId && <ListItemSecondaryAction><Switch onChange={(e) => {this.handleSelectCal(cal.id, e)}} checked={selectedCals.indexOf(cal.id) !== -1} /></ListItemSecondaryAction> }
            
          </ListItem>
        ))}
        </List>
        <List subheader={<ListSubheader>Current Active Calendar</ListSubheader>} className={classes.calList}>
        {recentCals.map(cal => (
          <ListItem  button onClick={()=>{this.props.ensureDrawerClose();CAFE.navigate("CAL", {calId: cal.id, key: cal.accessKeys})}} key={cal.id} selected={cal.id === currentCalId}>
            <ListItemText color="secondary" primary={cal.title} />
            
              {cal.id !== currentCalId && <ListItemSecondaryAction><Switch onChange={(e) => {this.handleSelectCal(cal.id, e)}} checked={selectedCals.indexOf(cal.id) !== -1} /></ListItemSecondaryAction> }
            
          </ListItem>
        ))}
        </List>
        {!authUser ||
        <List subheader={<ListSubheader>Saved Calendars</ListSubheader>} className={classes.calList}>
        {savedCals.map(savedCal => (
          <ListItem  button onClick={()=>{this.props.ensureDrawerClose();CAFE.navigate("CAL", {calId: savedCal.cal.id, key: savedCal.accessKeys})}} key={savedCal.cal.id} selected={savedCal.cal.id === currentCalId}>
            <ListItemText color="secondary" primary={savedCal.friendlyTitle || savedCal.cal.title} />
            
              {savedCal.cal.id !== currentCalId && <ListItemSecondaryAction><Switch onChange={(e) => {this.handleSelectCal(savedCal.cal.id, e)}} checked={selectedCals.indexOf(savedCal.cal.id) !== -1} /></ListItemSecondaryAction> }
            
          </ListItem>
        ))}
        </List>
        }
        
        {/* authUser ? (
        <List>
            <ListItem button onClick={()=>CAFE.navigate("SETTINGS")}>
              <ListItemText primary="Settings" />
            </ListItem>
            <ListItem button onClick={() => CAFE.signOut(me.handleProfileClose())}>
              <ListItemText primary="Sign Out" />
            </ListItem>
        </List>
        ) : (
        <List>
            <ListItem button onClick={()=>this.handleSignUp()}>
              <ListItemText primary="Create Account" />
            </ListItem>
            <ListItem button onClick={()=>this.handleSignIn()}>
              <ListItemText primary="Sign In" />
            </ListItem>
        </List>
        ) */}
        <List>
        </List>
        
      </div>
    )
    
    return (
        <nav className={classes.drawer}>
            {/* The implementation can be swap with js to avoid SEO duplication of links. */}
            <Hidden smUp implementation="css">
              <SwipeableDrawer
                container={this.props.container}
                variant="temporary"
                anchor= 'right' //{theme.direction === 'rtl' ? 'right' : 'left'}
                open={this.props.mobileOpen}
                onOpen={this.props.handleDrawerToggle}
                onClose={this.props.handleDrawerToggle}
                classes={{
                  paper: classes.drawerPaper,
                }}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
                style={{width: "90%"}}
              >
                <div className={classes.drawerHeader}>
                  <IconButton onClick={this.props.handleDrawerToggle}>
                    <ChevronRightIcon />
                  </IconButton>
                </div>
                <div tabIndex={0} role="button" /*onClick={this.handleDrawerToggle} onKeyDown={this.handleDrawerToggle}*/>
                  {drawer}
                </div>
              </SwipeableDrawer>
            </Hidden>
            <Hidden xsDown implementation="css">
              <Drawer
                classes={{
                  paper: classes.drawerPaper,
                }}
                variant="permanent"
                open
              >
                {drawer}
              </Drawer>
            </Hidden>
          </nav>
    )
  }
}

export default withStyles(styles)(MainNav) 
