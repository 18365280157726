import React, { Component } from 'react';
const {CAFE} = window

export default class Dash extends Component {
  

  render() {
    const me = this;
    const props = me.props || {};
    CAFE.log(`RENDERING: dash`, props);
    
    return (
      <div>
        <h1> DASHBOARD </h1>
      </div>
    );
  }
}

