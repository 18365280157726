import React, { Component } from 'react';
import { DisplayAgenda } from '../components/displayAgenda/displayAgenda';
import { Link } from 'react-router-dom'
import '../App.css';
import  moment from  'moment/moment';

class Agenda extends Component {


    constructor(props) {
        super(props)
        var split = this.props.showDate.split("-");
        var monthName = moment(split[1], 'M').format('MMM');
        var year = moment(split[2], 'Y').format('Y');
        this.state = {
            'date' : this.props.showDate,
            'dateToDisplay' : monthName ,
            createdEvents : this.props.createdEvents,

        }
        console.log(this.state.dateObject);
        console.log('Date',this.props.date);



    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    toggleAgenda = () => {
        console.log('toggle agenda');
        this.setState({agenda: true})
    }



    getSortedEvents(){
        
        var expArr = [];
        console.log('startAt,createdEvents',this.props.createdEvents);
        this.props.createdEvents.map((event,index)=>{
          var endAt = moment(event.endAt);
          var startAt = moment(event.startAt);
          var totalDays = endAt.diff(startAt, 'days') + 1;
         
          if(totalDays > 0){    
              for (var i = 0; i < totalDays; i++){  
                  const newEvent = {
                      ...event,
                      eventDate: moment(event.startAt).add(i,'days')                  }        
                  expArr.push(newEvent);
              } 
          }
          else {
              const newEvent = {
                  ...event,
                  eventDate: moment(event.startAt)              }
              expArr.push(newEvent);
          }
        })
  
        let sortedEventsArray = expArr.sort((a, b) => {
          return a.eventDate - b.eventDate;
          
        });
        console.log('hix74128 sortedEventsArray',sortedEventsArray );
       
        return sortedEventsArray;
        
    }

    getFilteredEvents(){
        
        var split = this.props.showDate.split("-")
        const currentYear = parseInt(split[2] , 10 );
        const currentMonth = parseInt( split[1] , 10 );
        const sortedEvents = this.getSortedEvents();
        
        var filteredEvents = [];
        for(var i = 0 ; i < sortedEvents.length; i++){

            var eventYear = sortedEvents[i].eventDate.year();
            var eventMonth = sortedEvents[i].eventDate.month() + 1;
            if(eventYear === currentYear && eventMonth === currentMonth){
                filteredEvents.push(sortedEvents[i]);
            }
        }
        console.log('filtered',filteredEvents);
        return filteredEvents;

    }
        

    render() {
   
        const filteredEvents = this.getFilteredEvents();
        if(this.props.createdEvents){
            return (        
                filteredEvents.map((event,index)=>{
                    return(
                        <DisplayAgenda key = {index} createdEvent = {event} />
                    )
                })
            )
        }
        else{
            return(<DisplayAgenda />)
        }
    }
}


export default Agenda;