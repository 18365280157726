import './cafeUtil.js'
import './rx.js'
import './dash/calRx.js'
import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import 'bootstrap/dist/css/bootstrap.min.css';
import * as serviceWorker from './serviceWorker'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider } from 'material-ui-pickers'
const {CAFE} = window


class AppContainer extends Component {
    render() {
        const store = CAFE.getStore()
        return <MuiPickersUtilsProvider utils={DateFnsUtils}><App store={ store.toJS() } /></MuiPickersUtilsProvider>
    }
    componentDidMount(){
        const me = this
        CAFE.onTrigger('update', function(){ 
            const store = CAFE.getStore()
            console.log("** STORE UPDATED **", store.toJS())
            me.forceUpdate() })
        CAFE.trigger("APP_LOADED")
        me.forceUpdate()
    }
}

ReactDOM.render(<AppContainer />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
