import React, { Component } from 'react';
// import { Link } from 'react-router-dom'
//import '../App.css';



class Test extends Component {

    constructor(props) {
        super(props)

        this.state = {
            // 'date' : this.props.location.state.date,
        }
        // console.log(this.state.date );
    }



    render() {
        return (

            <div style={{padding:'25px'}}>

            it is a test

            </div>
        );
    }

}


export default Test;