import { Auth } from 'aws-amplify'
import gql from 'graphql-tag'
let {CAFE} = window

CAFE.onTrigger("APP_LOADED", (params) => {
    CAFE.getStore().cal.set({recentCals: JSON.parse(window.sessionStorage.getItem('recentCals') || '[]') || []})
    CAFE.getStore().app.set("loaded", true).now()
    CAFE.startRouter()
})

CAFE.onTrigger("ROUTE_CHANGED", (params) => {
    
    if(params.name === "SIGNOUT") {
        Auth.signOut().then(() => {
          CAFE.trigger("AUTH_SIGNED_OUT")
        }).catch(e => {
          console.log("error signing out: ",e)
        })
    }
    
    CAFE.getStore().app.set("route", params).now()
    if(params.params && params.params.calId) {
        const cleanedCalId = CAFE.cleanNanoId(params.params.calId)
        // todo:  clean access keys as well
        CAFE.getStore().cal.set({currentCalId: cleanedCalId})
        CAFE.trigger("LOAD_CAL", {calId: cleanedCalId, accessKeys: params.params.key})
    }
})

CAFE.onTrigger("AUTH_CHANGED", (params) => {
    if(!params.username) return
    CAFE.setBusy("AUTH_CHANGED", true)
    
    ;(async () => { 
        const accountId = params.username
        
        const client = CAFE.getClient()
        await client.hydrated()
        
        const fetchResult = await client.query({
            query: gql(`query GetAccount($id: ID!) {
              getAccount(id: $id) {
                id
                name
                givenName
                familyName
                username
                birthDate
                timezone
                email
                emailVerified
                pictureUrl
              }
            }`),
            variables: {id: accountId},
            fetchPolicy: 'network-only',
        })
        let account = fetchResult.data ? fetchResult.data.getAccount : false
        console.log("CHECKED FOR USER, FOUND:", account)
        
        if(!account || !account.id) {
            const attributes = params.attributes
            const newAccount = CAFE.removeEmptyProps( {
                id: accountId,
            	sub: attributes.sub,
            	source: accountId.startsWith("Google_") ? "Google" : "cafe",
            	name: attributes.name || `${attributes.given_name || ""} ${attributes.family_name || ""}`.trim() || "",
            	givenName: attributes.given_name || "",
            	familyName: attributes.family_name || "",
            	username: accountId,
            	//birthDate: AWSDate,
            	//timezone: String!,
            	email: attributes.email,
            	emailVerified: attributes.email_verified || false,
            	createdAt: (new Date()).toISOString(),
            	createdBy: accountId
              })
            console.log("CREATING ACCOUNT...:", newAccount)
            // create user if not found
            const createResult = await client.mutate({
                mutation: gql(`mutation CreateAccount($input: CreateAccountInput!) {
                  createAccount(input: $input) {
                                id
                                name
                                givenName
                                familyName
                                username
                                birthDate
                                timezone
                                email
                                emailVerified
                                pictureUrl
                }}`),
                variables: {
                  input: newAccount
                }
            })
            console.log("CREATED ACCOUNT, FOUND:", createResult)
            account = createResult.data ? createResult.data.createAccount : false
            
        }
        
        
        if(account && account.id) CAFE.getStore().app.set({auth: {account: account, username: account.name ? account.name : account.username}}).now()
        else CAFE.getStore().app.set({auth: {}}).now()
        
        CAFE.trigger("FETCH_SAVEDCALS", {accountId:accountId})
        CAFE.setBusy("AUTH_CHANGED", false)
    })()
    
})

CAFE.onTrigger("AUTH_SIGNED_OUT", (params) => {
    const store = CAFE.getStore()
    store.app.set({auth: {}}).now()
    Auth.signOut({ global: true })
    .then(data => console.log("Signed out: ", data))
    .catch(err => console.log("ERROR signing out: ", err))

})

