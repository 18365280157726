import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import '../App.css';
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import AddIcon from '@material-ui/icons/Add'
import back from './back.png'
import  moment from  'moment/moment';
import Month from "./Month";
import DayDetail from "./DayDetail";
import Agenda from "./Agenda";

class Day extends Component {

    weekdayshort = moment.weekdaysShort();
    constructor(props) {
        super(props)
        const events = this.props.createdEvents;
        var split = this.props.showDate.split("-")
        this.state = {
            'date' : this.props.date,
            dateObject: moment(this.props.date,"YYYY-MM-DD").dayOfYear(),
            'weekday' :moment(this.props.date,"YYYY-MM-DD").weekday(),
            'days':  moment().dayOfYear() ,
            'monthName' : moment(split[1], 'M').format('MMMM'),
            'year' : moment(split[2], 'Y').format('YYYY'),
            'dayNo':'',
            'months' : [0,1,2,3,4,5,6,7,8,9,10,11],
            'agenda' :true,
            // 'className' : 'display-inline-flex',
        }
    }

    daysInMonth = () => {
        return this.state.dateObject.daysInMonth();
    };

    dayNameInYear = () => {
        let day = this.state.dayNo;
        return moment().year(2019).dayOfYear(day).format("ddd");
    }
    dayDate = () => {
        let day = this.state.dayNo;
        return moment().year(2019).dayOfYear(day).format("DD");
    }
    dayMonth = () => {
        let day = this.state.dayNo;
        return moment().year(2019).dayOfYear(day).format("MMM");
    }

    firstDayOfMonth = () => {
        let dateObject = this.state.dateObject;
        return dateObject;
        // let firstDay = moment(dateObject,"DD-MM-YYYY")
        //     .startOf("month")
        //     .format("d");
        // return firstDay;
    };

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    toggleAgenda = () => {
        console.log('toggle agenda');
        if (this.state.agenda == true){
            this.setState({agenda: false})
        }else {
            this.setState({agenda: true})
        }

    }
    


    render() {
    
        let weekdayshortname = this.weekdayshort.map(day => {
            return <th key={day}>{day}</th>;
        });

        let days = [];
            // this.daysInMonth();
        let d = this.state.dateObject;
        console.log(d);
        let w = this.state.weekday;
        console.log(this.state.weekday);
        let selected = d;
        d= d- w;
        // d =moment().day(-7).format('YYYY-MM-DD');
        let final = d+100;
        for (d ; d <= final; d++ ){
            this.state.dayNo =d;
            days.push(
                <td className=" ">
                    {/*<p>{this.dayMonth()}</p>*/}
                    <p className="margin-bottom">{this.dayNameInYear()}</p>
                    <p className="margin-bottom">{this.dayDate()}</p>
                </td>
            );
            // days = d;
        }

        var totalSlots = [...days];
        let rows = [];
        let cells = [];

        totalSlots.forEach((row, i) => {
            if (i % 7 !== 0) {
                cells.push(row);
            } else {
                rows.push(cells);
                cells = [];
                cells.push(row);
            }
            if (i === totalSlots.length - 1) {
                // let insertRow = cells.slice();
                rows.push(cells);
            }
        });

        let week = rows.map((d, i) => {
            console.log('d',d);
            return <tr className="col-12 week-scroll">{d}</tr>;

        });
        week.shift();   

        return (
         

            <div>
                
                    <div className=" day-scrollable">
                        <div className="row">
                            <div className="col-2 col-md-2 col-lg-1 my-month" style={{color : 'red' ,display: 'flex',alignItems: 'flex-end'}} onClick={() => this.props.goToYearDetail(this.props)}>
                               {/* <span className="pointer" style={{fontSize:'xx-large'}}>&lt;</span> */}
                               <div><img src={back} alt="" style= {{height:'21px'}} /></div>
                               <div className="pointer" style={{fontSize:'medium'}}>{this.state.monthName }&nbsp;{this.state.year}</div>
                            </div>
                           <div className="col-2 col-md-2 col-lg-2">    
                           </div>
                        </div>
                    </div>


                {!this.state.agenda && (
                    <DayDetail />
                )}
             
                {!this.state.agenda || (
                    <Agenda createdEvents={this.props.createdEvents} 
                    showDate = {this.props.showDate}/>
                )}

            </div>
        );
    }

}


export default Day;