import React, { Component } from 'react'
import { Auth } from 'aws-amplify'
import logo from '../cafe-b.svg'
import logoWord from '../cafe-word-w.svg'
import AppBar from '@material-ui/core/AppBar'
import Tooltip from '@material-ui/core/Tooltip'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import { withStyles } from '@material-ui/core/styles'
import MenuIcon from '@material-ui/icons/Menu'
import AccountCircle from '@material-ui/icons/AccountCircle'

const {CAFE} = window

//const appBarHeight = 64
const styles = theme => ({
  root: {
    display: 'flex',
  },
  grow: { 
    flexGrow: 1
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1
  },
  menuButton: {
    marginRight: 20,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  }

})


/* ************ TopBar *************  */
class TopBar extends Component {
  
  constructor(props) {
    super(props);
    this.signOut = this.signOut.bind(this);
    this.state = {
    }
  }
  
  componentDidMount() {
  }

  handleSignIn = event => {
    if(this.props.OAuthSignIn) this.props.OAuthSignIn()
  }

  handleProfileClick = event => {
    this.setState({ profileAnchor: event.currentTarget });
  }

  handleProfileClose = () => {
    this.setState({ profileAnchor: null });
  }
  
  signOut() {
    Auth.signOut().then(() => {
      CAFE.trigger("AUTH_SIGNED_OUT")
    }).catch(e => {
      console.log("error signing out: ",e);
    });
  }
  
  
  render() {
    const me = this;
    const { classes, account, authBusy } = me.props
    const { profileAnchor } = (me.state || {})
    const profileOpen = Boolean(profileAnchor)
    const authUser = account ? (account.name || account.username) : false
    
    return (
              <AppBar  position="fixed" className={classes.appBar}>
                <Toolbar>
                  <Button onClick={()=>CAFE.navigate("HOME")}><img src={logo} style={{ height: "40px"}} alt="cafe"  /><img src={logoWord} style={{ height: "40px"}} alt="cafe"  /></Button>
                  <div className={classes.grow}></div>
                  {!authBusy && (
                    authUser ? (
                      <div>
                        <Tooltip title={authUser}>
                          <IconButton color="inherit" onClick={me.handleProfileClick} >
                            <AccountCircle />
                          </IconButton>
                        </Tooltip>
                        <Menu
                          id="menu-profile"
                          anchorEl={profileAnchor}
                          anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'center',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                          }}
                          getContentAnchorEl={null}
                          open={profileOpen}
                          onClose={me.handleProfileClose}
                        >
                          <MenuItem onClick={() => CAFE.navigate("SETTINGS", me.handleProfileClose())}>Settings</MenuItem>
                          <MenuItem onClick={() => CAFE.signOut(me.handleProfileClose())}>Sign Out</MenuItem>
                        </Menu>
                      </div>
                    ) : (
                      <div>
                        <Button color="inherit" onClick={()=>this.handleSignIn()}>Sign In</Button>
                      </div>
                    ))}
                  
                  <IconButton
                    color="inherit"
                    aria-label="Open drawer"
                    onClick={this.props.handleDrawerToggle}
                    className={classes.menuButton}
                    style={{marginRight: 0}}>
                    <MenuIcon />
                  </IconButton>
                  
                </Toolbar>
              </AppBar>
             
    )
  }
}
export default withStyles(styles)(TopBar);
