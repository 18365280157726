import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import '../App.css';

import  moment from  'moment/moment';
import Month from "./Month";


class DayDetail extends Component {

    constructor(props) {
        super(props)

        this.state = {
            // 'date' : this.props.location.state.date,
        }
        // console.log(this.state.date );
    }



    render() {
        return (

            <div style={{marginTop: '25px'}}>
                <div className=" row event-row"  >
                     <div className="col-2 col-sm-1 col-md-2 col-lg-1 time">12 AM</div>
                    <input className="col-8 col-sm-4 col-md-6 col-lg-10 input-event" ></input>
                </div>
                <div className=" row event-row"  >
                     <div className="col-2 col-sm-1 col-md-2 col-lg-1 time"> 1 AM</div>
                    <input className="col-8 col-sm-4 col-md-6 col-lg-10 input-event" ></input>
                </div>
                <div className=" row event-row"  >
                     <div className="col-2 col-sm-1 col-md-2 col-lg-1 time"> 2 AM</div>
                    <input className="col-8 col-sm-4 col-md-6 col-lg-10 input-event" ></input>
                </div>
                <div className=" row event-row"  >
                     <div className="col-2 col-sm-1 col-md-2 col-lg-1 time"> 3 AM</div>
                    <input className="col-8 col-sm-4 col-md-6 col-lg-10 input-event" ></input>
                </div>
                <div className=" row event-row"  >
                     <div className="col-2 col-sm-1 col-md-2 col-lg-1 time"> 4 AM</div>
                    <input className="col-8 col-sm-4 col-md-6 col-lg-10 input-event" ></input>
                </div>
                <div className=" row event-row"  >
                     <div className="col-2 col-sm-1 col-md-2 col-lg-1 time"> 5 AM</div>
                    <input className="col-8 col-sm-4 col-md-6 col-lg-10 input-event" ></input>
                </div>
                <div className=" row event-row"  >
                     <div className="col-2 col-sm-1 col-md-2 col-lg-1 time"> 6 AM</div>
                    <input className="col-8 col-sm-4 col-md-6 col-lg-10 input-event" ></input>
                </div>
                <div className=" row event-row"  >
                     <div className="col-2 col-sm-1 col-md-2 col-lg-1 time"> 7 AM</div>
                    <input className="col-8 col-sm-4 col-md-6 col-lg-10 input-event" ></input>
                </div>
                <div className=" row event-row"  >
                     <div className="col-2 col-sm-1 col-md-2 col-lg-1 time"> 8 AM</div>
                    <input className="col-8 col-sm-4 col-md-6 col-lg-10 input-event" ></input>
                </div>
                <div className=" row event-row"  >
                     <div className="col-2 col-sm-1 col-md-2 col-lg-1 time"> 9 AM</div>
                    <input className="col-8 col-sm-4 col-md-6 col-lg-10 input-event" ></input>
                </div>
                <div className=" row event-row"  >
                     <div className="col-2 col-sm-1 col-md-2 col-lg-1 time">10 AM</div>
                    <input className="col-8 col-sm-4 col-md-6 col-lg-10 input-event" ></input>
                </div>
                <div className=" row event-row"  >
                     <div className="col-2 col-sm-1 col-md-2 col-lg-1 time">11 AM</div>
                    <input className="col-8 col-sm-4 col-md-6 col-lg-10 input-event" ></input>
                </div>
                <div className=" row event-row"  >
                     <div className="col-2 col-sm-1 col-md-2 col-lg-1 time">12 PM</div>
                    <input className="col-8 col-sm-4 col-md-6 col-lg-10 input-event" ></input>
                </div>

                <div className=" row event-row"  >
                     <div className="col-2 col-sm-1 col-md-2 col-lg-1 time">1 PM</div>
                    <input className="col-8 col-sm-4 col-md-6 col-lg-10 input-event" ></input>
                </div>
                <div className=" row event-row"  >
                     <div className="col-2 col-sm-1 col-md-2 col-lg-1 time">2 PM</div>
                    <input className="col-8 col-sm-4 col-md-6 col-lg-10 input-event" ></input>
                </div>
                <div className=" row event-row"  >
                     <div className="col-2 col-sm-1 col-md-2 col-lg-1 time">3 PM</div>
                    <input className="col-8 col-sm-4 col-md-6 col-lg-10 input-event" ></input>
                </div>
                <div className=" row event-row"  >
                     <div className="col-2 col-sm-1 col-md-2 col-lg-1 time">4 PM</div>
                    <input className="col-8 col-sm-4 col-md-6 col-lg-10 input-event" ></input>
                </div>
                <div className=" row event-row"  >
                     <div className="col-2 col-sm-1 col-md-2 col-lg-1 time">5 PM</div>
                    <input className="col-8 col-sm-4 col-md-6 col-lg-10 input-event" ></input>
                </div>
                <div className=" row event-row"  >
                     <div className="col-2 col-sm-1 col-md-2 col-lg-1 time">6 PM</div>
                    <input className="col-8 col-sm-4 col-md-6 col-lg-10 input-event" ></input>
                </div>
                <div className=" row event-row"  >
                     <div className="col-2 col-sm-1 col-md-2 col-lg-1 time">7 PM</div>
                    <input className="col-8 col-sm-4 col-md-6 col-lg-10 input-event" ></input>
                </div>
                <div className=" row event-row"  >
                     <div className="col-2 col-sm-1 col-md-2 col-lg-1 time">8 PM</div>
                    <input className="col-8 col-sm-4 col-md-6 col-lg-10 input-event" ></input>
                </div>
                <div className=" row event-row"  >
                     <div className="col-2 col-sm-1 col-md-2 col-lg-1 time">9 PM</div>
                    <input className="col-8 col-sm-4 col-md-6 col-lg-10 input-event" ></input>
                </div>
                <div className=" row event-row"  >
                     <div className="col-2 col-sm-1 col-md-2 col-lg-1 time">10 PM</div>
                    <input className="col-8 col-sm-4 col-md-6 col-lg-10 input-event" ></input>
                </div>
                <div className=" row event-row"  >
                     <div className="col-2 col-sm-1 col-md-2 col-lg-1 time">11 PM</div>
                    <input className="col-8 col-sm-4 col-md-6 col-lg-10 input-event" ></input>
                </div>



            </div>
        );
    }

}


export default DayDetail;