import React, { Component } from 'react'
import { Formik } from "formik"
import * as Yup from "yup"
import ChipInput from 'material-ui-chip-input'
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import Switch from "@material-ui/core/Switch"
import FormControl from "@material-ui/core/FormControl"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormGroup from "@material-ui/core/FormGroup"
import FormLabel from "@material-ui/core/FormLabel"
import SnackbarContent  from "@material-ui/core/SnackbarContent"
import Dialog  from "@material-ui/core/Dialog"
import DialogTitle  from "@material-ui/core/DialogTitle"
import DialogContent  from "@material-ui/core/DialogContent"
//import DialogContentText  from "@material-ui/core/DialogContentText"
import DialogActions  from "@material-ui/core/DialogActions"
import withStyles from "@material-ui/core/styles/withStyles"
const {CAFE} = window

const styles = theme => ({
    snackbarContent: {
        background: theme.palette.primary.dark,
        margin: theme.spacing.unit
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    container: {
        maxWidth: "500px"
    }
})


const validationSchema = Yup.object({
title: Yup.string("Enter a title for your calendar")
  .required("Title is required")
  .min(3, "Title must contain at least 2 characters")
  .max(80, "Title may contain at most 80 characters"),
description: Yup.string("Enter a description")
  .max(1000, "Description may contain at most 1000 characters"),
requireApprovalForView: Yup.boolean("Do you want to have to approve each person that tries to view this calendar?"),
tags: Yup.array()
  .max(10, "Each calendar can have at most 10 tags"),
})

const UpdateCalForm = props => {
 const {
   values: { title, description, requireApprovalForView, tags },
   errors,
   touched,
   handleChange,
   handleSubmit,
   isSubmitting,
   isValid,
   setFieldTouched,
   setFieldValue
 } = props

 const change = (name, e) => {
   e.persist();
   handleChange(e);
   setFieldTouched(name, true, false);
 }
 

 
 return (
    <form onSubmit={handleSubmit}>
        <TextField
            id="title"
            name="title"
            helperText={touched.title ? errors.title : ""}
            error={touched.title && Boolean(errors.title)}
            label="Title"
            value={title}
            onChange={change.bind(null, "title")}
            fullWidth
            margin="normal"
            variant="outlined"
        />
        <TextField
            id="description"
            name="description"
            multiline
            helperText={touched.description ? errors.description : ""}
            error={touched.description && Boolean(errors.description)}
            label="Description"
            fullWidth
            value={description}
            onChange={change.bind(null, "description")}
            margin="normal"
            variant="outlined"
        />
        <ChipInput
          label="Tags"
          value={tags}
          error={touched.tags && Boolean(errors.tags)}
          helperText={touched.tags ? errors.tags : ""}
          newChipKeyCodes={[13,32]}
          onAdd={(chip) => { 
            chip = chip.substring(0,10)
            if(tags.indexOf(chip) > -1) return
            tags.push(chip); 
            setFieldValue.bind(null, "tags")(tags); 
            setFieldTouched.bind(null, "tags", true, true)}
          }
          onDelete={(chip, index) => { tags.splice(index,1); setFieldValue.bind(null, "tags")(tags); setFieldTouched.bind(null, "tags", true, true)}}
            onBlur={setFieldTouched.bind(null, "tags", true, true)}
            fullWidth
            margin="normal"
            variant="outlined"
        />
        <FormControl component="fieldset" style={{display: "block"}} margin="normal">
            <FormLabel component="legend">Approval</FormLabel>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    id='requireApprovalForView' 
                    checked={requireApprovalForView}
                    onChange={change.bind(null, "requireApprovalForView")}
                    value="gilad"
                  />
                }
                label="Do you want to have to approve each person that tries to view this calendar?"
              />
            </FormGroup>
        </FormControl>
        <Button
            margin="normal"
            type="submit"
            variant="contained"
            color="primary"
            disabled={!isValid || isSubmitting}
            style={{minWidth: "100px"}}
        >
            Save
        </Button>
    </form>
 )
}



class UpdateCal extends Component {

    handleSignInButton = () => {
        if(this.props.OAuthSignIn) this.props.OAuthSignIn()
    }

    render() {
        console.log("RENDERING",this.props)
        const me = this
        const props = me.props || {}
        const {auth, classes, currentCal, handleClose, fullScreen} = props
        const accountId = auth && auth.account ? auth.account.id : false
        
        
        if(!accountId) return ( <SnackbarContent className={classes.snackbarContent}
          message={<span id="message-id">You must be signed in to create a calendar!</span>}
          action={[
            <Button key="signin" color="secondary" size="large" onClick={me.handleSignInButton}>
              Sign In or Sign Up
            </Button>
          ]}
        /> )
        
        const values = CAFE.setEmptyStringProps({ 
            title: currentCal.title, 
            description: currentCal.description, 
            requireApprovalForView: currentCal.requireApprovalForView,
            tags: currentCal.tags ? currentCal.tags.slice(0) : []})
        return (
             <Dialog
                  fullScreen={fullScreen}
                  open
                  onClose={handleClose}
                  aria-labelledby="responsive-dialog-title"
                >
                  <DialogTitle id="responsive-dialog-title">{`${currentCal.title} - Edit Calendar `}</DialogTitle>
                  <DialogContent>
                    <Formik
                        render={props => <UpdateCalForm {...props} />}
                        initialValues={values}
                        validationSchema={validationSchema}
                        onSubmit={(values, { setSubmitting }) => {
                            setTimeout(() => {
                                CAFE.trigger("UPDATE_CAL", {
                                    ...values,
                                    calId: currentCal.id,
                                    accountId: accountId,
                                    onError: (result) => { console.log("UPDATE CAL ERROR:", result); alert("ERROR"); setSubmitting(false) },
                                    onSuccess: (result) => { 
                                        console.log("UPDATE CAL SUCCESS:", result)
                                        CAFE.trigger("FETCH_SAVEDCALS", {accountId:accountId})
                                        CAFE.trigger("LOAD_CAL", {accountId:accountId, calId: currentCal.id})
                                        setSubmitting(false)
                                        handleClose()
                                }})
                            }, 400);
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" autoFocus>
                      Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default withStyles(styles)(UpdateCal)
