import React, { Component } from 'react';
import '../App.css';
import './about.css';
import logo from '../cafe-w.png';
const {CAFE} = window


export default class Events extends Component {
  

  render() {
    const me = this;
    const props = me.props || {};
    CAFE.log(`RENDERING: about`, props);
    
    return (
      <div>
          <header className="App-header">
            <img src={logo} className="App-logo" alt="logo" />
          </header>
      </div>
    );
  }
}

