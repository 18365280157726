import React, { Component } from 'react';
import withStyles from "@material-ui/core/styles/withStyles"
import CheckIcon from '@material-ui/icons/RadioButtonChecked' //CheckCircleOutline'
import UncheckIcon from '@material-ui/icons/RadioButtonUnchecked'
//const {CAFE} = window

const styles = theme => ({

    tagChip: {
      display: "inline-block",
      fontSize: "12px",
      lineHeight: "12px",
      borderRadius: "10px",
      padding: "2px 4px 4px 2px",
      border: "1px solid #fff",
      borderColor: theme.palette.secondary.light,
      color: theme.palette.secondary.light,
      cursor: "pointer",
      marginRight: "2px",
    },
    tagChipSelected: {
      display: "inline-block",
      fontSize: "12px",
      lineHeight: "12px",
      borderRadius: "10px",
      padding: "2px 4px 4px 2px",
      border: "1px solid #fff",
      borderColor: theme.palette.secondary.light,
      backgroundColor: theme.palette.secondary.light,
      color: "#fff",
      cursor: "pointer",
      marginRight: "2px",
    },
    tagIcon: {
      fontSize: "12px",
      lineHeight: "12px",
      top: ".1em",
      position: "relative",
      paddingRight: "2px"
    }
  
})
class TagChip extends Component {
  

  render() {
    const me = this
    const {selected, classes, tag, handleSelectTag} = me.props
    
    return (
        <span className={selected ? classes.tagChipSelected : classes.tagChip} onClick={() => {if(handleSelectTag) handleSelectTag(tag)}}>
          {selected ? <CheckIcon className={classes.tagIcon} /> : <UncheckIcon className={classes.tagIcon} />}
          {tag}
        </span>
    );
  }
}

export default withStyles(styles)(TagChip)